import * as React from 'react'
import Header from "../components/header"
import Footer from "../components/footer"




  const Layout = ({ pageTitle, children }) => {

    return (
      <main className="bg-cream"> 
    
        <Header />

        {children}

        <Footer />

      </main>
    )

  }
  export default Layout