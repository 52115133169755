import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"


const Footer = () => {
    const data = useStaticQuery(graphql`
    {
      storyblokEntry(name: {eq: "navigation"}) {
        content
        name
      }
    }
  `)

    let story = JSON.parse(data.storyblokEntry.content)

    return (
        <div className="container mx-auto px-50 text-center p-4">
                <nav className="justify-between flex">
                    <p>© 2021 Hirst Yarns</p>

                    <ul className="flex flex-row gap-20">
                        {story.global[2].FooterLinks.map(footer_link => (
                        
                            <li className="text-base">
                                <Link to={`/${ footer_link.link_url.cached_url }`} className="text-coolGray-800 hover:underline hover:text-blue-700">
                                    { footer_link.link_name }
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
        </div>
    )
}

export default Footer

